/* eslint-disable indent */

(function() {
    var requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
    window.requestAnimationFrame = requestAnimationFrame;
})();

//const _chars = ["?","!","a","b","c","d","e","f","g","h","i","j","k","l","m","n","o","p","q","r","s","t","u","v","w","x","y","z","A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z","0","1","2","3","4","5","6","7","8","9"];
const _chars = ['▓', '▒', '░', '█', '▄', '▀'];

export default class ShuffleText {

    constructor(_element) {
        this._isActive      = false;
        this.element        = _element;
        this.html           = this.element.innerHTML;
        this.text           = this.element.innerText;
    }

    setText(_text) {
        this.text = _text;
    }

    start() {
        if(this._isActive) return;
        this._isActive = true;
        const startTime = new Date().getTime();

        const strings = this.text.split('');

        const spans = strings.map(str => {
            if (str === ' ') return ' ';
            else return `<span>${str}</span>`;
        });

        this.element.innerHTML = spans.join('');
        
        const elems = this.element.querySelectorAll('span');

        return Promise.all([...elems].map((elem, idx) => {
            elem.dataset.char = elem.innerHTML;
            elem.innerHTML = ' ';
            return this._shuffle(elem, startTime, Math.random() * 400, idx * 66);
        }))
        .then(res => {
            this._isActive = false;
            this.element.innerHTML = this.html;
        });
    }

    _shuffle(elem, start, duration, delay) {
        return new Promise((resolve, reject) => {
            let step = () => {
                let current = new Date().getTime();
                if( current - start > delay){
                    if(current - start < duration + delay){
                        let num = Math.floor(Math.random() * _chars.length);
                        elem.style.opacity = 0.5;
                        elem.innerHTML = _chars[num];
                        window.requestAnimationFrame(step);
                    }else{
                        elem.innerHTML = elem.dataset.char;
                        elem.style.opacity = 1;
                        resolve(start);
                    }
                }else{
                    window.requestAnimationFrame(step);
                }
            };
            window.requestAnimationFrame(step);
        });
    }
}