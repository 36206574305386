/* eslint-disable indent */
import $ from 'jquery';

const defaultOptions = {
    onChange: evt => console.log('fdfd')
};

export default class Carousel {
    constructor(_element, _options = {}) {
        this.options = Object.assign(defaultOptions, _options);
        this.element = _element;
        this.index = 0;
        this.numSlides = $(this.element).find('.custom-gallery-slide').length;

        this.createNavigation();
        this.update();
    }

    createNavigation() {
        var nextBtn = $('<button class="nextBtn">').on('click', this.nextSlide.bind(this));
		$(this.element).after(nextBtn);

		var prevBtn = $('<button class="prevBtn">').on('click', this.prevSlide.bind(this));
        $(this.element).before(prevBtn);
        
        $(window).on('keydown.jsCustomGallery', e => {
			switch(e.keyCode){
				case 39:
					this.nextSlide();
					break;
				case 37:
                    this.prevSlide();
					break;
			}
		});
    }

    prevSlide() {
        this.index =  this.index - 1 < 0 ? this.numSlides - 1 : this.index - 1;
        this.options.onChange({
            index: this.index,
            direction: 'prev'
        });
		this.update();
    }

    nextSlide() {
        this.index =  this.index + 1 >= this.numSlides ? 0 : this.index + 1;
        this.options.onChange({
            index: this.index,
            direction: 'next'
        });
		this.update();
    }

    loadSiblings(_index) {
		var prevPrevIndex = (this.index + this.numSlides - 2) % this.numSlides;
		var prevIndex = (this.index + this.numSlides - 1) % this.numSlides;
		var nextIndex = (this.index + 1) % this.numSlides;
		var nextNextIndex = (this.index + 2) % this.numSlides;
		
		var indexes = [prevPrevIndex, prevIndex, this.index, nextIndex, nextNextIndex];

		
		indexes.forEach(item => {
			$(this.element)
			.find('.custom-gallery-slide')
			.eq(item)
			.find('img')
			.addClass('lazyload');
		});
    }

    update(){
		this.loadSiblings(this.index);
		return $(this.element)
            .find('.custom-gallery-slide')
            .removeClass('active')
            .eq(this.index)
            .addClass('active')[0];
    };
    
    destroy() {
        console.log('destroy carrousel');
    }
}