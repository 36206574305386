/* eslint-disable indent */
// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';
import onmount from 'onmount';
import lazysizes from 'lazysizes';
//import ShuffleText from 'shuffle-text';
import ShuffleText from './ShuffleText';
import Carousel from './Carousel';


/* ========================================
	Toggle Menu
/* ======================================== */
$(() => {
	onmount('[data-js-menu-toggle]', function(evt){
		const logoFx = $('#logo [data-js-shuffle]').map((i, e) => {
			return new ShuffleText( e );
		});
		const infoFx = $('#info [data-js-shuffle]').map((i, e) => {
			return new ShuffleText( e );
		});

		$(this).on('click', evt => {
			$('body').toggleClass('open-info');
			logoFx.each((i, e) => {
				e.start();
			});
			if( $('body').hasClass('open-info') ){
				infoFx.each((i, e) => {
					e.start();
				});
			}
		});
	}, function(evt){
		$(this).off('click')
	});
});

/* ========================================
	Carousel
/* ======================================== */
$(() => {
	var carouselList = {};
	var x = 0;
	var y = 0;
	var t = Date.now();

	const makeVelocityCalculator = (e) => {
		var newX = e.clientX;
		var newY = e.clientY;
		var newT = Date.now();

		var xDist = newX - x;
		var yDist = newY - y;
		var interval = newT - t;

		var velocity = Math.sqrt(xDist*xDist+yDist*yDist)/interval;
		if( velocity > 1){
			$('body').removeClass('-hide-menu');
		}
		// update values:
		x = newX;
		y = newY;
		t = newT;
	};
	onmount('[data-js-carousel]', function(evt) {

		carouselList[evt.id] = new Carousel(this, {
			onChange: evt => {
				$('body').addClass('-hide-menu');
			}
		});

		$(document).on('mousemove', makeVelocityCalculator);
		
	}, function(evt){
		carouselList[evt.id].destroy();

		$(document).off('mousemove', makeVelocityCalculator);
	});
});

/* ========================================
	Init
/* ======================================== */
$(() => {
	onmount();
});